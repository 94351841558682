<template>
  <!--发布招工需求-->
  <div class="page">
    <div class="release-content">
      <div>
        <!--基本要求-->
        <div class="basic">
          <div class="title-tip">基本要求</div>
          <van-cell-group>
            <van-field
              v-model="form.title"
              label="招工标题"
              required
              placeholder="请输入招工标题"
            />
            <van-field
              v-model="form.content"
              type="textarea"
              label="招工描述"
              required
              placeholder="请输入描述"
            />
            <van-field
                v-model="form.workingPlaceName"
                readonly
                required
                label="干活地点"
                placeholder="请输入干活地点"
                right-icon="arrow"
                @click="showLocaltion=true"
            />
            <van-field
                v-model="workingAddressDetailsStr"
                label="详细地址"
                placeholder="请输入详细地址"
            />
            <van-field
              readonly
              required
              clickable
              :value="workerInfosStr"
              label="所需工种"
              placeholder="点击选择"
              @click="showWorkerInfos = true"
              right-icon="arrow"
            />
<!--            <van-field-->
<!--              readonly-->
<!--              clickable-->
<!--              :value="form.workerSkill"-->
<!--              label="工人技能"-->
<!--              placeholder="点击选择"-->
<!--              @click="showWorkerSkill = true"-->
<!--              right-icon="arrow"-->
<!--            />-->
<!--            <van-field-->
<!--              readonly-->
<!--              clickable-->
<!--              :value="conditionStr"-->
<!--              label="基本要求"-->
<!--              placeholder="点击选择"-->
<!--              @click="showWorkCondition = true"-->
<!--              right-icon="arrow"-->
<!--            />-->
            <van-field
              v-model="form.projectDuration"
              type="number"
              label="工程工期(天)"
              placeholder="请输入天数"
            />
            <!-- <van-field
              v-model="salary"
              type="number"
              label="薪资(天)"
              placeholder="请输入薪资"
            /> -->

            <!-- <van-cell
              title="选择日期区间"
              :value="date"
              @click="show = true"
              is-link
            />
            <van-calendar
              v-model="show"
              type="range"
              @confirm="onConfirmDate"
            /> -->
          </van-cell-group>
        </div>
        <!--平台服务-->
        <div class="platform">
<!--          <span class="platform-tip">平台服务</span>-->
<!--          <div class="platform-content">-->
<!--            <van-checkbox v-model="checkShortMsgQty" icon-size="24px">-->
<!--              短信推送服务-->
<!--            </van-checkbox>-->
<!--            <p>-->
<!--              前100条免费-->
<!--              <van-icon name="info-o" color="#F38003" />-->
<!--              <span>-->
<!--                <van-stepper-->
<!--                  class="stepper"-->
<!--                  v-model="form.shortMsgQty"-->
<!--                  input-width="40px"-->
<!--                  button-size="18px"-->
<!--                  integer-->
<!--                />条</span-->
<!--              >-->
<!--            </p>-->
<!--          </div>-->
          <div class="push" style="display: none">
            <van-checkbox v-model="checkRewardQty" icon-size="24px">
              内推奖励
            </van-checkbox>
            <p>
              推荐朋友达成交易奖励
              <van-icon name="info-o" color="#F38003" />
              <span>
                <van-stepper
                  class="stepper"
                  v-model="form.rewardQty"
                  input-width="40px"yuanyuzhou
                  button-size="18px"
                  integer
                />元宝</span
              >
            </p>
          </div>
        </div>
      </div>
      <!-- 所需工种弹出选择 -->
      <van-action-sheet v-model="showWorkerInfos"
                        title="项目所需工种"
                        cancel-text="添加完成"
                        close-on-click-action
                        @cancel="showWorkerInfosOkM"
      >
        <div class="content">
          <div
            v-for="(w, index) in form.workerInfos"
            :key="index"
            class="solid"
          >
            <div class="solid-close" @click="delWorkerInfos(index)"><van-icon name="cross" color="#ee0a24" size="16" /></div>
            <van-field
              readonly
              clickable
              :value="w.workType"
              label="请选择工种"
              required
              placeholder="点击选择"
              @click="
                () => {
                  workInfosIndex = index;
                  showWorkerInfosSub = true;
                }
              "
              right-icon="arrow"
            />
            <van-field
              type="number"
              v-model="w.peopleQty"
              label="招聘人数"
              placeholder="请输入招聘人数"
              required
            />
            <van-field
              type="number"
              v-model="w.remuneration"
              placeholder="面议"
              label="劳务报酬"
              ri
            >
              <div slot="button">元/每天</div>
            </van-field>
          </div>
          <van-button block @click="addWorkerInfos">继续添加所需工种</van-button>
        </div>
      </van-action-sheet>
      <!-- 所需工种弹出选择 -->
      <van-popup v-model="showWorkerInfosSub" position="bottom">
        <van-picker
          show-toolbar
          :columns="workTypes"
          @confirm="onWorkerInfosSub"
          @cancel="onCancel"
          :default-index="0"
          cancel-button-text="清除已选"
        />
      </van-popup>
      <!-- 工人技能 -->
<!--      <van-action-sheet v-model="showWorkerSkill" title="工人技能标签">-->
<!--        <div class="content">-->
<!--          <van-checkbox-group v-model="form.workerSkill">-->
<!--            <van-checkbox-->
<!--              :name="item"-->
<!--              v-for="item in workerSkills"-->
<!--              :key="item"-->
<!--              >{{ item }}</van-checkbox-->
<!--            >-->
<!--          </van-checkbox-group>-->
<!--        </div>-->
<!--      </van-action-sheet>-->
      <!-- 工人技能 -->
      <van-action-sheet v-model="showWorkCondition" title="招工基本要求">
        <div class="content">
          <van-field
            readonly
            clickable
            :value="form.genderCondition"
            label="性别"
            required
            placeholder="点击选择"
            @click="showWorkConditionSubGender = true"
            right-icon="arrow"
          />
          <van-field
            readonly
            clickable
            :value="form.ageCondition"
            label="年龄"
            required
            placeholder="点击选择"
            @click="showWorkConditionSubAge = true"
            right-icon="arrow"
          />
          <van-field
            readonly
            clickable
            :value="form.experienceCondition"
            label="经验"
            required
            placeholder="点击选择"
            @click="showWorkConditionSubExperience = true"
            right-icon="arrow"
          />
        </div>
      </van-action-sheet>
      <!-- 性别 -->
      <van-popup v-model="showWorkConditionSubGender" position="bottom">
        <van-picker
          show-toolbar
          :columns="genders"
          @confirm="
            (v) => {
              form.genderCondition = v;
              showWorkConditionSubGender = false;
            }
          "
          @cancel="onCancel"
          :default-index="0"
          cancel-button-text="清除已选"
        />
      </van-popup>
      <!-- 年龄 -->
      <van-popup v-model="showWorkConditionSubAge" position="bottom">
        <van-picker
          show-toolbar
          :columns="ages"
          @confirm="
            (v) => {
              form.ageCondition = v;
              showWorkConditionSubAge = false;
            }
          "
          @cancel="onCancel"
          :default-index="0"
          cancel-button-text="清除已选"
        />
      </van-popup>
      <!-- 经验 -->
      <van-popup v-model="showWorkConditionSubExperience" position="bottom">
        <van-picker
          show-toolbar
          :columns="experiences"
          @confirm="
            (v) => {
              form.experienceCondition = v;
              showWorkConditionSubExperience = false;
            }
          "
          @cancel="onCancel"
          :default-index="0"
          cancel-button-text="清除已选"
        />
      </van-popup>

      <van-popup position="bottom" v-model="showLocaltion">
        <van-area title="选择地区" :area-list="areaList" @confirm="onConfirmLocal"/>
      </van-popup>
    </div>

    <div class="btn-footer">
      <van-button v-if="integral>=Math.abs(integralType.发布需求)" round type="info" block @click="save">提交审核</van-button>
      <van-button v-else round type="info" block disabled>积分元宝数量不足请联系客服充值</van-button>
    </div>
  </div>
</template>


<script>
import { save, update } from "@/api/demand";
import { areaList } from '@vant/area-data';
import {Dialog, Notify, Toast} from "vant";
import {getIntegralT} from "../../../api/user";
import {getDictsMap,getDicts} from "../../../api/dict";
function createWorkInfo() {
  return {
    workType: undefined,
    peopleQty: 1,
    remuneration: undefined,
  };
}
export default {
  props:{value:{type:Object,default:()=> ({})}},
  data() {
    return {
      integral:0,
      integralType:{
        '发布需求': 10
      },
      areaList,
      workingAddressDetailsStr:undefined,
      form: {
        title: "",
        content: "",
        workerInfos: [createWorkInfo()],
        workerSkill: [],
        ageCondition: "",
        experienceCondition: "",
        genderCondition: "",
        projectDuration: "",
        workingPlaceName: "",
        workingPlaceCoordinate: "",
        shortMsgQty: 0,
        rewardQty: 0,
        type: "RECRUITMENT",
      },
      checkShortMsgQty: false,
      checkRewardQty: false,
      showWorkerInfos: false,
      showWorkerSkill: false,
      showWorkCondition: false,
      showWorkerInfosSub: false,
      showWorkConditionSubGender: false,
      showWorkConditionSubAge: false,
      showWorkConditionSubExperience: false,
      showLocaltion:false,
      result: [],
      //   工种
      workTypes: [],
      //   工人技能
      workerSkills: [],
      workInfosIndex: 0,
      genders: ["不限", "男", "女"],
      ages: ["18岁以上", "30岁左右", "40岁左右", "50岁左右", "60岁左右"],
      experiences: ["2年以上", "3年以上", "5年以上", "8年以上", "10年以上"],
    };
  },
  mounted() {
    this.initForm();
    this.loadData();
  },
  watch:{
    value(){this.initForm()}
  },
  methods: {
    initForm(){
      this.form = Object.assign({},this.form, this.value)
    },
    async save() {
      if (!this.form.title) {Notify({type: 'warning',message: '请填写标题'});return false;}
      if (!this.form.content) {Notify({type: 'warning',message: '请填写内容'});return false;}
      if (!this.form.workingPlaceName) {Notify({type: 'warning',message: '请填写地址'});return false;}
      if (!this.form.workerInfos[0].workType) {Notify({type: 'warning',message: '请选择工种'});return false;}
      let form = JSON.parse(JSON.stringify(this.form))
      form.shortMsgQty = this.checkShortMsgQty ? form.shortMsgQty : 0;
      form.rewardQty = this.checkRewardQty ? form.rewardQty : 0;
      if (this.workingAddressDetailsStr){
        this.form.workingPlaceName+="-"+this.workingAddressDetailsStr
      }

      if (this.form.id) {
        await update(this.form.id, this.form);
        Dialog.alert({
          message: '提交成功，客服审核中。审核结果将通过公众号通知您，请注意查看。',
        }).then(() => {
          history.back();
        });
      } else {
        Dialog.confirm({
          title: '提醒',
          message: '发布本条信息将消耗'+Math.abs(this.integralType.发布需求)+'积分点击确定发布',
        })
        .then(() => {
          this.saveMeth()
        })
        .catch(() => {
          // on cancel
        });
      }

      // Toast.success("发布成功");
      // setTimeout(() => {
      //   history.back();
      // }, 600)
    },
    async saveMeth(){
      await save(this.form);
      Dialog.alert({
        message: '提交成功，客服审核中。审核结果将通过公众号通知您，请注意查看。',
      }).then(() => {
        history.back();
      });
    },
    formatDate(date) {
      return `${date.getMonth() + 1}/${date.getDate()}`;
    },
    onConfirmDate(date) {
      const [start, end] = date;
      this.show = false;
      this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`;
    },
    onConfirmLocal(v){
      this.form.workingPlaceName = v.map(o=>o.name).reduce((x,y)=>x+'-'+y)
      this.showLocaltion = false
    },
    async loadData() {
      this.workTypes = (await getDicts({ name: "工人类型" })).map(
        (o) => o.value
      );
      this.workerSkills = (await getDicts({ name: "工人技能" })).map(
        (o) => o.value
      );
      this.integralType = await getDictsMap({ name: "积分类型" })
      this.integral = (await getIntegralT()).data;
      console.info("loadData",this.integralType,this.integral)
    },
    go() {},
    onWorkerInfosSub(v) {
      this.form.workerInfos[this.workInfosIndex].workType = v;
      this.showWorkerInfosSub = false;
    },
    onCancel() {
      this.showWorkerInfosSub = false;
      this.showWorkConditionSubAge = false;
      this.showWorkConditionSubGender = false;
      this.showWorkConditionSubExperience = false;
    },
    addWorkerInfos() {

      this.form.workerInfos.push(createWorkInfo());
    },
    showWorkerInfosOkM(){
      this.form.workerInfos.forEach(wi=>{
        if (!wi.workType){
          Dialog({ message: '请选择工种' });
          this.showWorkerInfos=true;
        }
        if (!wi.peopleQty||wi.peopleQty<0){
          Dialog({ message: '招聘人数不可为空' });
          this.showWorkerInfos=true;
        }
      })
      // this.showWorkerInfos=false;
    },
    delWorkerInfos(index){
      this.form.workerInfos.splice(index,1);
    }
  },
  computed: {
    workerInfosStr() {
      return this.form.workerInfos
        .map((o) => o.workType?(o.workType+','+o.peopleQty+'人'):'')
        .reduce((x, y) => x + " | " + y);
    },
    conditionStr() {
      return (
        (this.form.ageCondition ? "年龄:" + this.form.ageCondition : "") +
        (this.form.genderCondition
          ? " 性别:" + this.form.genderCondition
          : "") +
        " " +
        this.form.experienceCondition
      );
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  background: #fff;
  .title-tip {
    font-size: 16px;
    font-weight: 600;
    padding: 15px;
  }
}
.release-content {
  min-height: calc(100vh - 180px);
}

.platform {
  background: #ffffff;
  margin-top: 30px;
  .platform-tip {
    font-size: 16px;
    font-weight: 600;
    margin-left: 15px;
    background: #ffffff;
  }
  .platform-content {
    margin-top: 15px;
    margin-left: 15px;
    border-bottom: 1px solid #ebedf0;
    p {
      font-size: xx-small;
      color: #8b8d90;
      margin-left: 30px;
      span {
        margin-left: 35%;
        font-size: xx-small;
        .stepper {
          display: inline;
        }
      }
    }
  }
  .push {
    margin-top: 15px;
    margin-left: 15px;
    border-bottom: 1px solid #ebedf0;
    p {
      font-size: xx-small;
      color: #8b8d90;
      margin-left: 30px;
      span {
        margin-left: 15%;
        font-size: xx-small;
        .stepper {
          display: inline;
        }
      }
    }
  }
}
.sms-push {
  padding: 15px;
  font-size: small;
  color: #3c3c3c;
}
.btn-footer {
  padding: 15px 10px;
}

.content {
  padding: 16px 16px;
  min-height: calc(100vh - 260px);
  .solid {
    border: 1px solid #eee;
    margin-bottom: 15px;
    .solid-close{
      width: 16px;
      height: 16px;
      float: right;
      border-radius: 10px;
      margin-top: -8px;
      margin-right: -8px;
      border: 1px solid #ee0a24;
    }
  }
}
</style>
<style lang="less" >
.content {
  .van-checkbox {
    padding: 15px;
    border-bottom: 1px solid #eee;
  }
}
</style>