import { render, staticRenderFns } from "./demand.vue?vue&type=template&id=a2b93b3c&scoped=true&"
import script from "./demand.vue?vue&type=script&lang=js&"
export * from "./demand.vue?vue&type=script&lang=js&"
import style0 from "./demand.vue?vue&type=style&index=0&id=a2b93b3c&lang=less&scoped=true&"
import style1 from "./demand.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2b93b3c",
  null
  
)

export default component.exports