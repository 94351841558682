import axios from 'axios'
import store from '../store'
import router from '../router';
import {
    Toast
} from 'vant';
import { SET_TOKEN } from '../const.js'
//  开发时使用
// export const BASE_URL = 'http://localhost:8883/wx/';
export const BASE_URL = 'http://admin.91workman.com/wx/';

const service = axios.create({ //这个service是自定义（你自己起的名字）
    baseURL: BASE_URL, //请求的公共地址
    timeout: 5000, // 请求超时时间
})

service.interceptors.request.use(
    config => {
        if (store.state.token) { // 判断是否存在token，如果存在的话，则每个http header都加上token
            config.headers['X-TC-Token'] = `${store.state.token}`;
        }
        return config;
    },
    err => {
        return Promise.reject(err);
    }
)


// http response 拦截器
service.interceptors.response.use(
    response => {
        console.info("http response:", response)
        let d = response.data
        if (d.errno == 501) {
            store.commit(SET_TOKEN, undefined);
            router.replace({
                path: 'login',
                query: {
                    redirect: router.currentRoute.fullPath
                }
            })
        } else if (d.errno != 0) {
            Toast.fail(d.errmsg);
            return Promise.reject(d.errmsg)
        } else {
            return d.data;
        }

    },
    error => {
        console.error("http response error:", response)
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    // 返回 401 清除token信息并跳转到登录页面
                    store.commit(SET_TOKEN, undefined);
                    if (router.currentRoute.fullPath.includes('register')) {
                        // skip
                    } else if (!router.currentRoute.fullPath.includes('login')) {
                        router.replace({
                            path: 'login',
                            query: {
                                redirect: router.currentRoute.fullPath
                            }
                        })
                    }
                default:
                    Toast.fail(error.response.data ? error.response.data : '操作失败');
            }
        }
        return Promise.reject(error.response.data) // 返回接口返回的错误信息
    }
)

export default service