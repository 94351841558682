import axios from '../http'

export function getUserInfo(data) {
    return axios.get('sysUser', data)
}

/**
 * 检查是否已积分签到
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function checkIntegral(data) {
    return axios.get('integral/check', data)
}

/**
 * 积分签到
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function signIntegral(data) {
    return axios.get('integral/sign', data)
}

/**
 * 积分记录
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getIntegral(data) {
    return axios.get('integral', data)
}

export function getIntegralT(data) {
    return axios.get('integral/t', data)
}

/**
 * 获取元宝数据列表
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getTreasure(data) {
    return axios.get('treasure', data)
}

/**
 * 获取我的邀请人列表
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getInvite(data) {
    return axios.get('sysUser/invite', data)
}
