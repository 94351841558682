import axios from '../http2'

/**
 * save
 * @param {code} data 
 * @returns 
 */
export function save(data) {
    return axios.post('/Demand', data)
}

/**
 * update
 * @param {*} data 
 * @returns 
 */
export function update(id,data) {
    return axios.put('/Demand/'+id, data)
}
/**
 * findAll
 * @param {*} params 
 * @returns 
 */
export function findAll(params) {
    return axios.get('/Demand', {params})
}
export function findByKey(params) {
    return axios.get('/Demand/key', {params})
}

export function adminGetDate(params) {
    return axios.get('/Demand/admin', {params})
}

export function adminOK(params) {
    return axios.put('/Demand/ok', params)
}

export function adminFail(params) {
    return axios.put('/Demand/fail', params)
}

export function myDate(params) {
    return axios.get('/Demand/user', {params})
}


/**
 * get
 * @param {*} id 
 * @returns 
 */
 export function get(id) {
    return axios.get('/Demand/'+id)
}

export function finish(id) {
    return axios.put('/Demand/finish/'+id)
}


export function getPhone(id) {
    return axios.get('/Demand/'+id+'/contacts-tel')
}
export function checkPhone(id) {
    return axios.get('/DemandContacts/'+id)
}