import axios from 'axios'
import store from '../store'
import router from '../router'
import { Toast } from 'vant';
import { SET_TOKEN } from '../const.js'

//  开发时使用
export const BASE_URL = 'http://admin.91workman.com/wx/';
// export const BASE_URL = 'http://localhost:8883/wx/';
// export const BASE_URL = 'http://127.0.0.1:9098/';

axios.defaults.baseURL = BASE_URL;
axios.defaults.timeout = 30000;

axios.interceptors.request.use(
    config => {
        if (store.state.token) { // 判断是否存在token，如果存在的话，则每个http header都加上token
            config.headers['X-TC-Token'] = `${store.state.token}`;
        }
        return config;
    },
    err => {
        return Promise.reject(err);
    }
)


// http response 拦截器
axios.interceptors.response.use(
    response => {
        console.log("http response:", response)
        let d = response.data
        if (d.errno == 501) {
            store.commit(SET_TOKEN, undefined);
            router.replace({
                path: 'login',
                query: {
                    redirect: router.currentRoute.fullPath
                }
            })
        } else if (d.errno != 0) {
            Toast.fail(d.errmsg);
            return Promise.reject(d.errmsg)
        } else {
            return response.data;
        }
    },
    error => {
        console.info("http response error:", error.response)
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    // 返回 401 清除token信息并跳转到登录页面
                    store.commit(SET_TOKEN, undefined);
                    if (router.currentRoute.fullPath.includes('register')) {
                        // skip
                    } else if (!router.currentRoute.fullPath.includes('login')) {
                        router.replace({
                            path: 'login',
                            query: { redirect: router.currentRoute.fullPath }
                        })
                    }
                default:
                    this.$toast.fail(error.response.data ? error.response.data : '操作失败');
            }
        }
        return Promise.reject(error.response.data) // 返回接口返回的错误信息
    }
)

export default axios