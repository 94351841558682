<template>
  <!--发布需求-->
  <div class="page">
    <div class="release-content">
      <div>
        <!--基本要求-->
        <div class="basic">
          <van-cell-group>
            <van-field
              v-model="form.title"
              label="需求标题"
              required
              placeholder="请输入需求标题"
            />
            <van-field
              v-model="form.content"
              type="textarea"
              label="需求描述"
              required
              placeholder="请输入描述"
            />
<!--            <van-field-->
<!--             required-->
<!--              label="项目标签"-->
<!--            >-->
<!--              <van-checkbox-group slot="input" class="check-group" v-model="form.tags">-->
<!--                <van-checkbox-->
<!--                  :name="item"-->
<!--                  v-for="item in tags"-->
<!--                  :key="item"-->
<!--                  >{{ item }}-->
<!--                </van-checkbox>-->
<!--              </van-checkbox-group>-->
<!--            </van-field>-->

<!--            <van-field-->
<!--              v-model="form.contactsTel"-->
<!--              label="联系人"-->
<!--              required-->
<!--              placeholder="请输入联系人"-->
<!--            />-->
<!--            <van-field-->
<!--              v-model="form.contactsName"-->
<!--              label="联系电话"-->
<!--              required-->
<!--              placeholder="请输入联系电话"-->
<!--            />-->

            <van-field
                v-model="form.workingPlaceName"
                readonly
                required
                label="需求地点"
                placeholder="请输入需求地点"
                right-icon="arrow"
                @click="showLocaltion=true"
            />
            <van-field
                v-model="workingAddressDetailsStr"
                label="详细地址"
                placeholder="请输入详细地址"
            />
          </van-cell-group>
        </div>
      </div>
      
    </div>

    <div class="btn-footer">
      <van-button v-if="integral>=Math.abs(integralType.发布需求)" round type="info" block @click="save">提交审核</van-button>
      <van-button v-else round type="info" block disabled>积分元宝数量不足请联系客服充值</van-button>
    </div>

    <van-popup position="bottom" v-model="showLocaltion">
        <van-area title="选择地区" :area-list="areaList" @confirm="onConfirmLocal"/>
      </van-popup>
  </div>
</template>


<script>
import { getDictsMap,getDicts } from "@/api/dict";
import { save, update } from "@/api/demand";
import { areaList } from '@vant/area-data';
import {Dialog, Notify} from 'vant';
import {getIntegralT} from "../../../api/user";
export default {
  props:{value:{type:Object,default:()=> ({})}},
  data() {
    return {
      integral:0,
      integralType:{
        '发布需求': 10
      },
      areaList,
      workingAddressDetailsStr: '',
      showLocaltion:false,
      form: {
        title: "",
        content: "",
        workingPlaceCoordinate:undefined,
        workingPlaceName:undefined,
        tags:[],
        contactsTel:undefined,
        contactsName:undefined,
        type: "DEMAND",
      },
      tags:[],
    };
  },
  mounted() {
    this.initForm()
    this.loadData();
  },
  watch:{
    value(){this.initForm()}
  },
  methods: {
    initForm(){
      this.form = Object.assign({},this.form, this.value)
    },
    async save() {
      if (this.workingAddressDetailsStr){
        this.form.workingPlaceName+="\\"+this.workingAddressDetailsStr
      }
      if (!this.form.title) {Notify({type: 'warning',message: '请填写标题'});return false;}
      if (!this.form.content) {Notify({type: 'warning',message: '请填写内容'});return false;}
      if (!this.form.workingPlaceName) {Notify({type: 'warning',message: '请填写地址'});return false;}
      // if (!this.form.contactsName) {Notify({type: 'warning',message: '请填写联系人'});return false;}
      // if (!this.form.contactsTel) {Notify({type: 'warning',message: '请填写手机号'});return false;}
      if (this.workingAddressDetailsStr){
        this.form.workingPlaceName+="-"+this.workingAddressDetailsStr
      }

      if(this.form.id){
        await update(this.form.id, this.form)
        Dialog.alert({
          message: '提交成功，客服审核中。审核结果将通过公众号通知您，请注意查看。',
        }).then(() => {
          history.back();
        });
      }else{
        Dialog.confirm({
          title: '提醒',
          message: '发布本条信息将消耗'+Math.abs(this.integralType.发布需求)+'积分点击确定发布',
        })
        .then(() => {
          this.saveMeth()
        })
        .catch(() => {
          // on cancel
        });
      }

    },
    async saveMeth(){
      await save(this.form);
      Dialog.alert({
        message: '提交成功，客服审核中。审核结果将通过公众号通知您，请注意查看。',
      }).then(() => {
        history.back();
      });
    },
    async loadData() {
      this.tags = (await getDicts({ name: "项目标签" })).map(
        (o) => o.value
      );
      this.integralType = await getDictsMap({ name: "积分类型" })
      this.integral = (await getIntegralT()).data;
    },
    onConfirmLocal(v){
      this.form.workingPlaceName = v.map(o=>o.name).reduce((x,y)=>x+'\\'+y)
      this.showLocaltion = false
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  background: #fff;
  .title-tip {
    font-size: 16px;
    font-weight: 600;
    padding: 15px;
  }
}
.release-content {
  min-height: calc(100vh - 180px);
}


.btn-footer {
  padding: 15px 10px;
}

.content {
  padding: 16px 16px;
  min-height: calc(100vh - 260px);
  .solid {
    border: 1px solid #eee;
    margin-bottom: 15px;
  }
}
</style>
<style lang="less" >
.check-group {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  .van-checkbox {
    padding-bottom: 10px;
  }
}
</style>